import React, { memo, useCallback, useRef, useState, useMemo } from "react"

import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { Handle, Position } from "reactflow"
import { get } from "lodash"

import { actions } from "reducers/notebookReducer"
import { selectNodes, selectNodeStatus, selectFocused } from "selectors"
import ComponentForm from "components/pages/Workflows/Edit/Notebook/Cell/ComponentForm"
import { FAMemo } from "@dbai/ui-staples"
import NodeIcon from "./NodeIcon"
import { StyledNode, Divider, NodeTitle, FormContainer } from "./StyledNode"

const NodeExpanderContainer = styled.div`
  float: bottom;
  right: 8px;
  position: fixed;
  padding-top: 13px;
  padding-right: 8px;
  padding-left: 20px;
`

const NodeExpander = props => {
  const downColor = "grey"
  const dropIcon = props.dropped ? faChevronUp : faChevronDown
  return (
    <FAMemo
      icon={dropIcon}
      size="xl"
      style={{ color: downColor, cursor: "pointer" }}
      {...props}
    />
  )
}

const ComponentNode = ({ data, selected, id, isConnectable, nodeIdx }) => {
  const nodeStatus = useSelector(state =>
    selectNodeStatus(state, { nodeId: id })
  )
  const dispatch = useDispatch()
  const previousSelected = useRef(selected)
  const { node: focusedNode } = useSelector(selectFocused)
  const focused = useMemo(() => {
    return focusedNode === id
  }, [focusedNode, id])
  const focusCell = useCallback(() => {
    // transition from not selected to selected
    if (selected && !previousSelected.current) {
      previousSelected.current = true
      dispatch(
        actions.focusCell({
          cellIdx: 0,
          node: id,
        })
      )
      return
    }

    // transition from selected to not selected
    if (!selected && previousSelected.current) {
      previousSelected.current = false
      dispatch(actions.clearFocus())
    }

    // transition from selected and not focused to selected and focused
    if (selected && previousSelected.current && !focused) {
      previousSelected.current = true
      dispatch(
        actions.focusCell({
          cellIdx: 0,
          node: id,
        })
      )
    }
  }, [dispatch, selected, focused, id])

  const specNodes = useSelector(selectNodes)
  const node = specNodes.find(node => node.id === id)
  const [dropped, setDropped] = useState(false)

  const handleDrop = useCallback(() => {
    setDropped(!dropped)
  }, [dropped])

  const indexes = specNodes.reduce(
    (pre, n, idx) => ({ [n.id]: idx, ...pre }),
    {}
  )

  if (!node) return null
  return (
    <StyledNode
      selected={selected}
      focused={focused}
      status={nodeStatus}
      onClick={focusCell}
      dropped={dropped}
      color={data.color}
      className="nowheel"
      nodeType="component"
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        selected={focused || selected}
      />
      <NodeTitle>
        <NodeIcon
          id={id}
          color={data.color}
          status={nodeStatus}
          type={data.type}
          isAction={Boolean(data.actionId)}
          selected={selected}
        />
      </NodeTitle>
      <NodeExpanderContainer onClick={handleDrop}>
        <NodeExpander dropped={dropped} />
      </NodeExpanderContainer>
      <label color={data.color}>{data.label}</label>
      <Divider focused={focused} selected={selected} status={nodeStatus} />
      {dropped ? (
        <FormContainer className="nowheel">
          <div>
            <ComponentForm
              node={node}
              nodeIdx={indexes[node.id]}
              selected={selected}
              componentPath={`/components/${get(node, "metadata.componentId", "")}`}
            />
          </div>
        </FormContainer>
      ) : null}
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        selected={focused || selected}
      />
    </StyledNode>
  )
}

export default memo(ComponentNode)
