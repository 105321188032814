import React, { useMemo, useState, useCallback } from "react"
import { Input } from "antd"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { FAMemo } from "@dbai/ui-staples"

import { actions } from "reducers/notebookReducer"
import {
  IconContainer,
  getIcon,
} from "components/pages/Workflows/Edit/shared/NodeText"
import { nodeIconMap } from "components/pages/Workflows/shared/IconPicker"

const Flow = styled.div`
  display: flex;
  flex-wrap: wrap;

  align-items: baseline;
`

const OptionFlow = styled(Flow)`
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: #deebff; // Taken from react-select
  }
`

const OptionText = styled.span`
  margin-left: 10px;
`

const Option = props => {
  const { data, onSelect } = props
  const { type, color, icon } = data?.value?.spec || {}
  const nodeIcon = icon || getIcon(type)

  return (
    <OptionFlow onClick={() => onSelect(data)}>
      <IconContainer color={color}>
        <FAMemo icon={nodeIconMap[nodeIcon]} fixedWidth />
      </IconContainer>
      <OptionText>{data.label}</OptionText>
    </OptionFlow>
  )
}

const OptionsFilter = props => {
  const { filter, onChange } = props

  return (
    <Input.Search
      placeholder="Start typing to filter"
      onChange={onChange}
      value={filter}
    />
  )
}

const getOptionKey = opt => {
  const { value = {} } = opt
  if (value.id) return value.id
  if (value.spec?.id) return value.spec.id
  return `${opt.label}${value.color}`
}

const ActionSelect = props => {
  const { filtered, selectOptions, pickerType } = props
  const [filter, setFilter] = useState("")
  const dispatch = useDispatch()
  const handleFilterChange = e => setFilter(e.target.value)
  const filteredOptions = useMemo(() => {
    if (!filter) return selectOptions
    const filterOptions = opt => opt.label.toLowerCase().match(filter)
    return {
      ...selectOptions,
      options: selectOptions.options.filter(filterOptions),
    }
  }, [filter, selectOptions])

  const patchNode = useCallback(
    state => {
      dispatch(actions.cloneIntoCurrentNode(state.value, pickerType))
    },
    [dispatch, pickerType]
  )

  const renderOption = opt => {
    const key = getOptionKey(opt)
    return <Option key={key} data={opt} onSelect={patchNode} />
  }

  if (filtered) {
    return (
      <>
        <OptionsFilter
          placeholder="Filter Actions"
          onChange={handleFilterChange}
          value={filter}
        />
        {filteredOptions.options.map(renderOption)}
      </>
    )
  }

  return <>{selectOptions.options.map(renderOption)}</>
}

export default ActionSelect
