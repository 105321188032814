import React, { useMemo, useCallback } from "react"
import { Tabs } from "antd"
import { useQuery } from "@apollo/client"
import { useSelector, useDispatch } from "react-redux"

import { storeToState } from "@dbai/tool-box"
import {
  Spinner,
  SidePane,
  ErrorMessage,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import ActionSelect from "./ActionSelect"
import { actions } from "reducers/notebookReducer"
import {
  GET_ACTIONS,
  GET_PUBLISHED_ACTIONS,
  GET_COMPONENTS,
  GET_PUBLISHED_COMPONENTS,
} from "queries"
import { selectFocusedNodeType, selectClonableNodes } from "selectors"
import * as GraphConfig from "components/pages/Workflows/Edit/shared/graphTypes"
import DBBoundary from "components/shared/DBBoundary"

const capitalize = s => `${s[0].toUpperCase()}${s.slice(1)}`
const filterBuiltins = ([t]) =>
  !["empty", "source", "sink", "download"].includes(t)
const builtinToOption = ([type, { graph, initialState }]) => ({
  label: capitalize(graph.typeText),
  value: { spec: { type, ...initialState } },
})

const builtins = Object.entries(GraphConfig.BuiltinTypes)
  .filter(filterBuiltins)
  .map(builtinToOption)

const builtinOptions = {
  label: "Add New",
  options: builtins,
}

const BuiltinActionsPicker = props => {
  return <ActionSelect selectOptions={builtinOptions} />
}

const BuiltinActions = props => {
  return (
    <>
      <BuiltinActionsPicker />
    </>
  )
}

const NodePicker = props => {
  const nodes = useSelector(selectClonableNodes)
  const options = useMemo(
    () => nodes.filter(n => n?.value?.spec?.type === "script"),
    [nodes]
  )

  const selectOptions = useMemo(
    () => ({
      label: "In This Workflow",
      options,
    }),
    [options]
  )

  return <ActionSelect filtered selectOptions={selectOptions} />
}

const actionToOption = ({ name, currentRevision = {} }) => ({
  label: name,
  value: storeToState({ name, ...currentRevision }),
})

const LibraryPicker = props => {
  const {
    query,
    variables = {},
    optionLabel,
    mapActions,
    pickerType = "action",
  } = props
  const { data, loading, error } = useQuery(query, {
    variables,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  })

  const options = useMemo(() => mapActions(data), [data, mapActions])

  const selectOptions = useMemo(
    () => ({
      label: optionLabel,
      options,
    }),
    [optionLabel, options]
  )

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <ActionSelect
      filtered
      pickerType={pickerType}
      selectOptions={selectOptions}
    />
  )
}

const ReusableActionPicker = props => {
  const [customer] = useCurrentCustomer()
  const variables = { cname: customer.normalizedName }

  const mapActions = useCallback(data => {
    return (
      data?.customer?.actions
        ?.filter(a => a.currentRevision)
        .map(actionToOption) || []
    )
  }, [])

  return (
    <LibraryPicker
      query={GET_ACTIONS}
      variables={variables}
      mapActions={mapActions}
      optionLabel="In Your Library"
      pickerType="action"
    />
  )
}

const ComponentPicker = () => {
  const [customer] = useCurrentCustomer()
  const variables = { cname: customer.normalizedName }

  const mapActions = useCallback(data => {
    return (
      data?.customer?.components
        ?.filter(a => a.currentRevision)
        .map(actionToOption) || []
    )
  }, [])

  return (
    <LibraryPicker
      query={GET_COMPONENTS}
      variables={variables}
      mapActions={mapActions}
      optionLabel="In Your Component Library"
      pickerType="component"
    />
  )
}
const PublishedComponentPicker = props => {
  const mapActions = useCallback(data => {
    return (
      data?.componentPublications
        ?.filter(cp => cp.component)
        .map(cp => actionToOption(cp.component)) || []
    )
  }, [])

  return (
    <LibraryPicker
      mapActions={mapActions}
      query={GET_PUBLISHED_COMPONENTS}
      optionLabel="In Public Library"
      pickerType="component"
    />
  )
}
const PublishedActionPicker = props => {
  const mapActions = useCallback(data => {
    return (
      data?.actionPublications
        ?.filter(ap => ap.action)
        .map(ap => actionToOption(ap.action)) || []
    )
  }, [])

  return (
    <LibraryPicker
      mapActions={mapActions}
      query={GET_PUBLISHED_ACTIONS}
      optionLabel="In Public Library"
      pickerType="action"
    />
  )
}

const PickerTabs = props => {
  const items = [
    {
      key: "publicComponents",
      label: "Public Components",
      children: <PublishedComponentPicker />,
    },
    {
      key: "components",
      label: "Components",
      children: (
        <DBBoundary>
          <ComponentPicker />
        </DBBoundary>
      ),
    },
    {
      key: "workflow",
      label: "In This Workflow",
      children: <NodePicker />,
    },
    {
      key: "actions",
      label: "Actions",
      children: <ReusableActionPicker />,
    },
    {
      key: "public",
      label: "Public Actions",
      children: <PublishedActionPicker />,
    },
  ]
  return <Tabs defaultActiveKey="publicComponents" items={items} />
}

const ActionPicker = props => {
  const selectedNodeType = useSelector(selectFocusedNodeType)
  const dispatch = useDispatch()
  const close = useCallback(() => {
    dispatch(actions.clearFocus())
  }, [dispatch])
  const isVisible = selectedNodeType === "empty"

  return (
    <SidePane
      title="Choose Action Type"
      isVisible={isVisible}
      width="30vw"
      onCloseClicked={close}
      mask
    >
      <BuiltinActions />
      <PickerTabs />
    </SidePane>
  )
}

export default ActionPicker
