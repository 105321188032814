import React, { useEffect, useState, useMemo, useCallback } from "react"
import styled from "styled-components"
import { Row, Col, Button, Typography } from "antd"
import path from "path"
import config from "config"
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons"
import {
  FileList,
  FileNavbar,
  FileBrowser,
  FileToolbar,
  ChonkyActions,
  ChonkyIconName,
  defineFileAction,
} from "chonky"

import { ErrorMessage, AuthBoundary, Content } from "@dbai/ui-staples"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import { useChonkySearchEventTrap } from "hooks"
import DatalakeUpload from "./DatalakeUpload"
import DirectoryForm from "./DirectoryForm"
import DeletionConfirmation from "./DeletionConfirmation"
import { Modal } from "@dbai/ui-staples"

import AWS from "aws-sdk"
import { toast } from "react-toastify"
import { fetchS3BucketContents } from "@dbai/ui-staples"
import { decryptDatalakeResources } from "@dbai/ui-staples"

const StyledContent = styled(Content)`
  height: calc(100vh - 134px);
`

const { Text, Paragraph } = Typography

const crypto = window.crypto
const key = config.datalakeEncryptionKey

const S3Datalake = ({ customer, themeMode }) => {
  const [bucket, setBucket] = useState(null)
  const [s3Client, setS3Client] = useState(null)
  const [showCredentials, setShowCredentials] = useState(false)
  const [decryptedCreds, setDecryptedCreds] = useState({})

  useEffect(() => {
    async function getResources() {
      const decrypted = await decryptDatalakeResources(
        customer.datalakeResources,
        key,
        crypto
      )
      setDecryptedCreds(decrypted)
      if (decrypted.Bucket) {
        setBucket(decrypted.Bucket)
      }
      if (decrypted.Credentials) {
        AWS.config.update({
          region: "us-east-1",
          accessKeyId: decrypted.Credentials.AccessKeyID,
          secretAccessKey: decrypted.Credentials.SecretAccessKey,
        })
        const s3 = new AWS.S3()
        setS3Client(s3)
      }
    }
    if (customer.datalakeResources) {
      getResources()
    }
  }, [customer.datalakeResources, setDecryptedCreds])

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Data Lake" },
  ]

  return (
    <AuthBoundary subject="datalake" action="LIST">
      <PageHeader routes={routes}>
        <Button type="primary" onClick={() => setShowCredentials(true)}>
          SHOW CREDENTIALS
        </Button>
      </PageHeader>
      <StyledContent>
        <Col span={24}>
          {bucket ? (
            <S3Browser
              themeMode={themeMode}
              customer={customer}
              bucket={bucket}
              s3Client={s3Client}
            />
          ) : null}
        </Col>
        <Modal
          isOpen={showCredentials}
          onRequestClose={() => setShowCredentials(false)}
        >
          <Paragraph strong>
            Copy and paste these credentials into your S3 client.
          </Paragraph>
          <Row type="flex" justify="space-around">
            <Col span={7}>
              <Text strong>Bucket:</Text>
            </Col>
            <Col span={17}>
              <Text copyable>{decryptedCreds?.Bucket}</Text>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={7}>
              <Text strong>Region:</Text>
            </Col>
            <Col span={17}>
              <Text copyable>us-east-1</Text>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={7}>
              <Text strong>Access Key ID:</Text>
            </Col>
            <Col span={17}>
              <Text copyable>{decryptedCreds?.Credentials?.AccessKeyID}</Text>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={7}>
              <Text strong>Secret Access Key:</Text>
            </Col>
            <Col span={17}>
              <Text copyable>
                {decryptedCreds?.Credentials?.SecretAccessKey}
              </Text>
            </Col>
          </Row>
        </Modal>
      </StyledContent>
    </AuthBoundary>
  )
}

const initChonkyFile = object => ({
  id: object.Key,
  name: path.basename(object.Key),
  modDate: object.LastModified,
  size: object.Size,
})

const initChonkyDir = prefix => ({
  id: prefix.Prefix,
  name: path.basename(prefix.Prefix),
  isDir: true,
})

const createChonkyActions = ({
  openUploadPane,
  setDeletionCandidates,
  openDirectoryModal,
  openFiles,
  downloadFiles,
  moveFiles,
}) => {
  return {
    upload: openUploadPane,
    add_new_directory: openDirectoryModal,
    download_selected: ({ state }) => {
      const files = state?.selectedFiles || []
      downloadFiles(files)
    },
    delete_selected: ({ state }) => {
      setDeletionCandidates(state.selectedFiles)
    },
    end_drag_n_drop: ({ payload }) => {
      moveFiles(payload)
    },
    [ChonkyActions.OpenFiles.id]: data => {
      openFiles(data)
    },
  }
}

const customFileActions = [
  defineFileAction({
    id: "add_new_directory",
    hotkeys: ["n"],
    button: {
      name: "New Folder",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.folderCreate,
    },
  }),
  defineFileAction({
    id: "upload",
    button: {
      name: "Upload",
      toolbar: true,
      contextMenu: true,
      icon: faCloudUploadAlt,
    },
  }),
  defineFileAction({
    id: "download_selected",
    requiresSelection: true,
    fileFilter: node => !node.isDir,
    button: {
      name: "Download",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.download,
    },
  }),
  defineFileAction({
    id: "delete_selected",
    hotkeys: ["del"],
    requiresSelection: true,
    button: {
      name: "Delete",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.trash,
    },
  }),
]

const openDownloadUrl = ({ url }) => {
  window.open(url, "_blank")
}

const checkIsRoot = currentPath => {
  if (currentPath === "root" || currentPath === "rootPath") return true
  return !currentPath || currentPath === "/"
}

const S3Browser = ({ s3Client, bucket, themeMode }) => {
  const [directoryModalOpen, setDirectoryModalOpen] = useState()
  const openDirectoryModal = useCallback(() => setDirectoryModalOpen(true), [])
  const closeDirectoryModal = () => setDirectoryModalOpen(false)

  const [uploadPaneOpen, setUploadPaneOpen] = useState(false)
  const openUploadPane = useCallback(() => setUploadPaneOpen(true), [])
  const closeUploadPane = () => setUploadPaneOpen(false)

  const [deletionCandidates, setDeletionCandidates] = useState([])
  const closeDeletionModal = useCallback(() => setDeletionCandidates([]), [])

  const [error, setError] = useState(null)
  const [folderPrefix, setKeyPrefix] = useState("/")
  const [files, setFiles] = useState([])

  useChonkySearchEventTrap()

  const refreshFiles = useCallback(() => {
    fetchS3BucketContents(s3Client, bucket, folderPrefix)
      .then(({ files, directories }) => {
        const chonkyFiles = []

        //remove the current folder from the list of files so we don't show a dot file
        files = files.filter(file => file.Key !== folderPrefix)

        if (files) {
          chonkyFiles.push(...files.map(initChonkyFile))
        }

        if (directories) {
          chonkyFiles.push(...directories.map(initChonkyDir))
        }

        return chonkyFiles
      })
      .then(setFiles)
      .catch(error => setError(error.message))
  }, [folderPrefix, bucket, setFiles, s3Client, setError])

  useEffect(() => {
    refreshFiles()
  }, [folderPrefix, refreshFiles, setFiles])

  const openFiles = useCallback(
    data => {
      if (data.payload.files && data.payload.files.length !== 1) return
      if (!data.payload.targetFile || !data.payload.targetFile.isDir) return

      const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`
      setKeyPrefix(newPrefix)
    },
    [setKeyPrefix]
  )

  const handleDelete = useCallback(() => {
    Promise.all(
      deletionCandidates.map(({ id: key }) => {
        return s3Client
          .deleteObject({ Bucket: bucket, Key: key })
          .promise()
          .catch(error => setError(error.message))
      })
    ).then(() => refreshFiles())
    closeDeletionModal()
  }, [deletionCandidates, s3Client, bucket, closeDeletionModal, refreshFiles])

  const getSignedPutUrl = useCallback(
    (file, callback) => {
      const fileName = file.name
      const body = file.file
      const contentType = file.type
      const filePath = `${folderPrefix}${fileName}`.replace(/^\//, "")
      const params = {
        Bucket: bucket,
        Key: filePath,
        Body: body,
        ContentType: contentType,
      }
      return s3Client.getSignedUrl("putObject", params, callback)
    },
    [folderPrefix, bucket, s3Client]
  )

  const downloadFiles = useCallback(
    files => {
      files.forEach(({ id: key }) => {
        const params = {
          Bucket: bucket,
          Key: key,
        }
        s3Client.getSignedUrl("getObject", params, (error, url) => {
          if (error) {
            setError(error.message)
          } else {
            openDownloadUrl({ url })
          }
        })
      })
    },
    [s3Client, bucket]
  )

  const moveFiles = useCallback(
    ({ destination = {}, selectedFiles = [], draggedFile = {} }) => {
      const files = selectedFiles.length > 0 ? selectedFiles : [draggedFile]
      const hasDir = files.some(({ isDir }) => isDir)
      if (hasDir) {
        toast.error("Moving folders is not supported")
      }
      const filesToMove = files.filter(({ isDir }) => !isDir)
      const targetDirectory = checkIsRoot(destination.id)
        ? ""
        : destination.id.slice(-1) === "/"
          ? destination.id
          : `${destination.id}/`
      Promise.all(
        filesToMove.map(async file => {
          const { id: key } = file
          const newKey = `${targetDirectory}${key.split("/").pop()}`
          await s3Client
            .copyObject({
              Bucket: bucket,
              CopySource: `${bucket}/${key}`,
              Key: newKey,
            })
            .promise()
          await s3Client
            .deleteObject({
              Bucket: bucket,
              Key: key,
            })
            .promise()
        })
      ).then(() => refreshFiles())
    },
    [s3Client, bucket, refreshFiles]
  )

  const createS3Object = useCallback(
    (key, file) => {
      if (file) {
        return s3Client
          .putObject({
            Body: file,
            Bucket: bucket,
            Key: key,
          })
          .promise()
      } else {
        return s3Client
          .putObject({
            Body: "",
            Bucket: bucket,
            Key: key,
          })
          .promise()
      }
    },
    [s3Client, bucket]
  )

  const createDirectory = useCallback(
    ({ directoryName }) => {
      closeDirectoryModal()
      const directoryPath = `${folderPrefix}${directoryName}/`.replace(
        /^\//,
        ""
      )
      createS3Object(directoryPath)
        .then(() => {
          setFiles([
            ...files,
            initChonkyDir({
              Prefix: directoryPath,
            }),
          ])
        })
        .catch(error => setError(error.message))
    },
    [files, setFiles, createS3Object, folderPrefix]
  )

  const fileActions = useMemo(
    () =>
      createChonkyActions({
        openUploadPane,
        openDirectoryModal,
        setDeletionCandidates,
        openFiles,
        downloadFiles,
        moveFiles,
      }),
    [
      moveFiles,
      openFiles,
      downloadFiles,
      openUploadPane,
      openDirectoryModal,
      setDeletionCandidates,
    ]
  )

  const selectAndRunHandler = useCallback(
    chonkyAction => {
      const handler = fileActions[chonkyAction.id]
      if (handler) handler(chonkyAction)
    },
    [fileActions]
  )

  const folderChain = useMemo(() => {
    let folderChain
    if (folderPrefix === "/") {
      folderChain = []
    } else {
      let currentPrefix = ""
      folderChain = folderPrefix
        .replace(/\/*$/, "")
        .split("/")
        .map(prefixPart => {
          currentPrefix = currentPrefix
            ? path.join(currentPrefix, prefixPart)
            : prefixPart
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
          }
        })
    }
    folderChain.unshift({
      id: "/",
      name: "root",
      isDir: true,
    })
    return folderChain
  }, [folderPrefix])

  if (error) return <ErrorMessage error={error} />

  return (
    <>
      <DatalakeUpload
        isVisible={uploadPaneOpen}
        handleClose={closeUploadPane}
        getSignedUrl={getSignedPutUrl}
        refreshFiles={refreshFiles}
      />
      <FileBrowser
        darkMode={themeMode === "dark"}
        instanceId={"S3 Datalake"}
        disableDragAndDropProvider={true}
        files={files}
        folderChain={folderChain}
        fileActions={customFileActions}
        onFileAction={selectAndRunHandler}
        defaultFileViewActionId={ChonkyActions.EnableListView.id}
      >
        <FileNavbar />
        <FileToolbar />
        <FileList />
      </FileBrowser>
      <DirectoryForm
        isOpen={directoryModalOpen}
        closeModal={closeDirectoryModal}
        handleSubmit={createDirectory}
      />
      <DeletionConfirmation
        onDelete={handleDelete}
        candidates={deletionCandidates}
        closeModal={closeDeletionModal}
      />
    </>
  )
}

export default withCustomer(S3Datalake)
