import React, { memo } from "react"
import ComponentForm from "./ComponentForm"
import { useCellSubscription } from "components/pages/Workflows/Edit/shared/util"

const NotebookComponentForm = ({ nodeIdx, node, componentPath }) => {
  useCellSubscription({
    nodeIdx,
    cellIdx: 2,
    cellUuid: node.cells[2].uuid,
    muted: node.cells[2].metadata.muted,
  })
  return (
    <ComponentForm
      node={node}
      nodeIdx={nodeIdx}
      componentPath={componentPath}
    />
  )
}

export default memo(NotebookComponentForm)
