import React, { useMemo, useCallback, useState } from "react"
import { toast } from "react-toastify"
import { useSelector, useDispatch } from "react-redux"

import dayjs from "dayjs"
import { Form, FAMemo } from "@dbai/ui-staples"
import { Button, Card, Switch, Tag, Tooltip } from "antd"
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons"

import Presets from "./Presets"
import SearchPackages from "./SearchPackages"
import { actions as advancedActions } from "reducers/scheduleReducer"
import CronControls, { FlexLabel, StyledSelect } from "./CronControls"
import {
  selectDescription,
  selectCrontab,
  selectHours,
  selectMinutes,
  selectDoM,
  selectDoW,
  selectMonth,
} from "selectors/schedule"

const getTzOptions = () => {
  const now = dayjs()
  return Intl.supportedValuesOf("timeZone").map(timezone => ({
    label: `${timezone} ${now.tz(timezone).format("z")} (${now.format("Z")})`,
    value: timezone,
  }))
}

const hourLabels = [
  "00 AM",
  "01 AM",
  "02 AM",
  "03 AM",
  "04 AM",
  "05 AM",
  "06 AM",
  "07 AM",
  "08 AM",
  "09 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "01 PM",
  "02 PM",
  "03 PM",
  "04 PM",
  "05 PM",
  "06 PM",
  "07 PM",
  "08 PM",
  "09 PM",
  "10 PM",
  "11 PM",
]

const dowLabels = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const monthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const dowOptions = dowLabels.map((label, value) => ({
  label,
  value: { value: String(value), step: null },
}))

const monthOptions = monthLabels.map((label, value) => ({
  label,
  value: { value: String(value + 1), step: null },
}))

const domOptions = [...Array(31).keys()].map((_, idx) => ({
  label: String(idx + 1),
  value: { value: String(idx + 1), step: null },
}))

const CronSelect = props => {
  const { selector, options, ...rest } = props
  const value = useSelector(selector)

  const realValue = useMemo(() => {
    const selected = value.map(v => v.value)
    return options
      .filter(o => selected.includes(o.value.value))
      .map(o => o.value)
  }, [value, options])

  return <StyledSelect value={realValue} options={options} {...rest} />
}

const NewSchedule = ({ customer, afterCreate }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.schedule)
  const isPkgSelected = useSelector(state => !!state.schedule.packageId)

  const description = useSelector(selectDescription)
  const crontab = useSelector(selectCrontab)

  const [presetValue, setPresetValue] = useState(1)
  const [presetUnit, setPresetUnit] = useState("minute")
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [presetCron, setPresetCron] = useState("")
  const [presetCronText, setPresetCronText] = useState("")

  const handlePresetChange = useCallback(
    (value, unit, time, selectedDay, cron, crontabText) => {
      setPresetValue(value)
      setPresetUnit(unit)
      setPresetCron(cron)
      setPresetCronText(crontabText)
    },
    []
  )

  const handleSwitchChange = useCallback(checked => {
    setShowAdvanced(checked)
  }, [])

  const handleSubmit = () => {
    const action = advancedActions.createSchedule({
      variables: { customerId: customer.id },
    })

    dispatch(action)
      .then(() => afterCreate && afterCreate())
      .then(() => toast.success("Created Schedule"))
  }

  return (
    <Form
      state={state}
      dispatch={dispatch}
      actions={advancedActions}
      onSubmit={handleSubmit}
      hideSubmit
    >
      <SearchPackages customer={customer} />
      {isPkgSelected ? (
        <div>
          <Card>
            <div
              style={{
                opacity: showAdvanced ? 0.5 : 1,
                pointerEvents: showAdvanced ? "none" : "auto",
              }}
            >
              <Presets onChange={handlePresetChange} customerId={customer.id} />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <FlexLabel
                className="form-label"
                style={{ marginRight: 10, width: 180 }}
              >
                Advanced Scheduling:
              </FlexLabel>
              <Switch checked={showAdvanced} onChange={handleSwitchChange} />
            </div>

            {showAdvanced && (
              <>
                <CronControls
                  cronField="hour"
                  labels={hourLabels}
                  label="Hour of the Day"
                  selector={selectHours}
                />

                <CronControls
                  cronField="minute"
                  max={59}
                  label="Minute of the Hour"
                  selector={selectMinutes}
                />

                <CronControls
                  cronField="dom"
                  name="Day of the Month"
                  addText="Add Day of the Month to Run on"
                  min={1}
                  max={31}
                  options={domOptions}
                  selector={selectDoM}
                  isMulti
                />

                <CronSelect
                  name="spec.month"
                  label="Month"
                  placeholder="Every Month"
                  options={monthOptions}
                  selector={selectMonth}
                  isMulti
                />

                <CronSelect
                  name="spec.dow"
                  label="Day of the Week"
                  placeholder="Every Day"
                  options={dowOptions}
                  selector={selectDoW}
                  isMulti
                />

                <StyledSelect
                  name="spec.timezone"
                  label="Timezone"
                  options={getTzOptions()}
                />
              </>
            )}
          </Card>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <FlexLabel className="form-label" style={{ width: 200 }}>
              Scheduled Run Time(s):
            </FlexLabel>
            Description:
            <Tag color="blue" style={{ marginLeft: 60 }}>
              {showAdvanced ? description : presetCronText}
            </Tag>
          </div>
          <div style={{ marginBottom: 20 }}>
            Cron Expression
            <Tooltip
              title="5 field cron expression for scheduling tasks. The fields are: minute - hour - Day of Month - Month - Day of Week. '*' means every value, '*/n' means the task will be performed every nth value, and 'n' is a start value. For example, ' 5 */4 3 2 * ' means the task will be performed at the 5th minute of every 4 hours, on the third day of the month in February."
              placement="right"
            >
              <FAMemo
                icon={faQuestionCircle}
                style={{ marginLeft: 2, marginRight: 2 }}
              />
            </Tooltip>
            :
            <Tag color="blue" style={{ marginLeft: 10 }}>
              {showAdvanced ? crontab : presetCron}
            </Tag>
          </div>

          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!isPkgSelected}
          >
            Submit
          </Button>
        </div>
      ) : null}
    </Form>
  )
}

export default NewSchedule
