import {
  faFont,
  faHashtag,
  faToggleOn,
  faCalendar,
  faCaretDown,
  faEyeDropper,
} from "@fortawesome/pro-solid-svg-icons"

import baseInputSchema from "./fields/BaseInput/fieldOptionsSchema"

import datePickerSchema from "./fields/DatePicker/fieldOptionsSchema"
import datePickerGenerateSchema from "./fields/DatePicker/generateSchema"

import colorPickerGenerateSchema from "./fields/ColorPicker/generateSchema"
import colorPickerFieldOptionsSchema from "./fields/ColorPicker/fieldOptionsSchema"

import numberInputGenerateSchema from "./fields/NumberInput/generateSchema"
import numberInputFieldOptionsSchema from "./fields/NumberInput/fieldOptionsSchema"
import switchInputFieldOptionsSchema from "./fields/SwitchInput/fieldOptionsSchema"

import localSelectGenerateSchema from "./fields/LocalSelect/generateSchema"
import localSelectFieldOptionsSchema from "./fields/LocalSelect/fieldOptionsSchema"

import columnSelectGenerateSchema from "./fields/ColumnSelect/generateSchema"
import columnSelectFieldOptionsSchema from "./fields/ColumnSelect/fieldOptionsSchema"

import datasetSelectGenerateSchema from "./fields/DatasetSelect/generateSchema"
import datasetSelectFieldOptionsSchema from "./fields/DatasetSelect/fieldOptionsSchema"

import datalakeSelectGenerateSchema from "./fields/DatalakeSelect/generateSchema"
import datalakeSelectFieldOptionsSchema from "./fields/DatalakeSelect/fieldOptionsSchema"

import appVariableSelectGenerateSchema from "./fields/AppVariableSelect/generateSchema"
import appVariableSelectFieldOptionsSchema from "./fields/AppVariableSelect/fieldOptionsSchema"

import schemaArrayGenerateSchema from "./containers/SchemaArray/generateSchema"
import schemaArrayFieldOptionsSchema from "./containers/SchemaArray/fieldOptionsSchema"

import schemaObjectGenerateSchema from "./containers/SchemaObject/generateSchema"
import schemaObjectFieldOptionsSchema from "./containers/SchemaObject/fieldOptionsSchema"

import columnValuesSelectSchema from "./fields/ColumnValueInput/fieldOptionsSchema"
import columnValuesSelectGenerateSchema from "./fields/ColumnValueInput/generateSchema"

import { processScalarFieldOptions } from "./lib/generateSchemaHelpers"

const scalarFormFields = {
  // core scalar types
  string: {
    label: "Text",
    icon: faFont,
    fieldOptionsSchema: baseInputSchema,
    generateSchema: processScalarFieldOptions,
  },
  number: {
    label: "Number",
    icon: faHashtag,
    generateSchema: numberInputGenerateSchema,
    fieldOptionsSchema: numberInputFieldOptionsSchema,
  },
  boolean: {
    label: "Switch",
    icon: faToggleOn,
    generateSchema: processScalarFieldOptions,
    fieldOptionsSchema: switchInputFieldOptionsSchema,
  },

  // additional scalar types
  dropdown: {
    label: "Dropdown",
    icon: faCaretDown,
    generateSchema: localSelectGenerateSchema,
    fieldOptionsSchema: localSelectFieldOptionsSchema,
  },
  colorPicker: {
    label: "Color Picker",
    icon: faEyeDropper,
    generateSchema: colorPickerGenerateSchema,
    fieldOptionsSchema: colorPickerFieldOptionsSchema,
  },
  datePicker: {
    label: "Date Picker",
    icon: faCalendar,
    fieldOptionsSchema: datePickerSchema,
    generateSchema: datePickerGenerateSchema,
  },

  datasetSelect: {
    label: "Dataset",
    generateSchema: datasetSelectGenerateSchema,
    fieldOptionsSchema: datasetSelectFieldOptionsSchema,
  },
  datalakeSelect: {
    label: "Datalake File",
    generateSchema: datalakeSelectGenerateSchema,
    fieldOptionsSchema: datalakeSelectFieldOptionsSchema,
  },
  columnSelect: {
    label: "Column Selector",
    generateSchema: columnSelectGenerateSchema,
    fieldOptionsSchema: columnSelectFieldOptionsSchema,
  },
  columnValuesSelect: {
    label: "Column Values",
    fieldOptionsSchema: columnValuesSelectSchema,
    generateSchema: columnValuesSelectGenerateSchema,
  },

  // app controls
  appVariable: {
    label: "App Variable",
    generateSchema: appVariableSelectGenerateSchema,
    fieldOptionsSchema: appVariableSelectFieldOptionsSchema,
  },
}

const containerFields = {
  object: {
    label: "Group",
    generateSchema: schemaObjectGenerateSchema,
    fieldOptionsSchema: schemaObjectFieldOptionsSchema,
  },
  array: {
    label: "List",
    generateSchema: schemaArrayGenerateSchema,
    fieldOptionsSchema: schemaArrayFieldOptionsSchema,
  },
}

const formFieldRegistry = {
  ...scalarFormFields,
  ...containerFields,
}

export { containerFields, scalarFormFields }
export default formFieldRegistry
