import React from "react"

import Select from "../../shared/Select"
import { getSelectMode } from "../../utils"
import ColumnValueSelect from "../ColumnValueSelect"
import { useFormFieldDropdownOptions } from "../../hooks"
import { withFormFieldWrapper } from "../../FormFieldWrapper"

const OptionSetSelect = props => {
  const { schema, parentName, ...rest } = props
  const options = useFormFieldDropdownOptions(schema, parentName)
  return <Select {...rest} options={options} />
}

const EnumSelect = props => {
  return <Select {...props} />
}

const LocalSelectCore = React.memo(props => {
  const { schema } = props
  const { nullable, metadata = {} } = schema
  const { options, listPath, optionsSource, onDropdownVisibleChange } = metadata
  const mode = getSelectMode(schema)

  if (options) {
    return (
      <Select
        {...props}
        options={options}
        onDropdownVisibleChange={onDropdownVisibleChange}
      />
    )
  }

  if (optionsSource === "datasetColumn") {
    return (
      <ColumnValueSelect
        {...props}
        onDropdownVisibleChange={onDropdownVisibleChange}
      />
    )
  }

  if (listPath) {
    return (
      <OptionSetSelect
        mode={mode}
        allowClear={nullable}
        {...props}
        onDropdownVisibleChange={onDropdownVisibleChange}
      />
    )
  }

  return (
    <EnumSelect
      mode={mode}
      allowClear={nullable}
      {...props}
      onDropdownVisibleChange={onDropdownVisibleChange}
    />
  )
})

const LocalSelect = withFormFieldWrapper(LocalSelectCore)
LocalSelect.Core = LocalSelectCore
export default LocalSelect
