import client from "apolloClient"
import { get, curry, isEqual, snakeCase } from "lodash"

import { storeToState, alphanumid } from "@dbai/tool-box"

import { cleanScript } from "lib/utils"
import { selectNodes, selectFocused } from "selectors/workflow"
import { UPDATE_WORKFLOW, RESTORE_WORKFLOW_FROM_SNAPSHOT } from "queries"
import {
  getNodePath,
  roundCoords,
  getNodeById,
  getCellById,
  getNodeAtIndex,
  getNodeIdxById,
  getNodesFromSpecOrNodes,
} from "lib/utils"
import {
  yDoc,
  syncSpec,
  syncYNode,
  syncYEdges,
  syncYCell,
  addYDocEdge,
  addYDocNode,
  inYTransaction,
  insertYDocCell,
  removeYDocCell,
  removeYDocNode,
  removeYDocEdge,
  updateYDocNode,
  ySetWorkflowName,
  syncYCellMetaData,
  updateYDocCellType,
  getYCellByIdAndIndex,
  updateYDocCellOutputs,
  updateYDocCellMetadata,
  updateYDocCellExecutionCount,
} from "lib/yjs"

export const ACTION_ID_LABEL = "actionId"
export const ACTION_REVISION_ID_LABEL = "actionRevisionId"

export const COMPONENT_ID_LABEL = "componentId"
export const COMPONENT_REVISION_ID_LABEL = "componentRevisionId"
export const COMPONENT_OUTPUT_NAME = "componentOutputName"

const initNewCell = () => ({
  uuid: alphanumid(),
  cellType: "code",
  metadata: {
    muted: false,
    showOutput: true,
  },
  source: [],
})
const initNewScriptCell = () => ({
  uuid: alphanumid(),
  cellType: "code",
  metadata: {
    muted: false,
    showCode: true,
    showOutput: true,
  },
  source: [],
})

const nodesPath = ["workflow", "spec", "nodes"]
const nodesPathString = nodesPath.join(".")
const nodesPathRegex = new RegExp(nodesPathString)

const updateYNodeAttributes = ({ getState, nodeId, keys }) => {
  const { nodes } = getState().notebook.workflow.spec
  const node = getNodeById(nodeId, nodes)

  updateYDocNode(node, keys)
}

export default actions => {
  const saveWorkflow = () => (dispatch, getState) => {
    const { notebook, currentCustomer } = getState()
    if (notebook.isDefaultState) return
    const sanitizedState = storeToState(notebook)
    const customerId = currentCustomer.customer.id
    const { id, tags, ...rest } = sanitizedState.workflow

    return client.mutate({
      mutation: UPDATE_WORKFLOW,
      fetchPolicy: "no-cache",
      variables: { id, customerId, input: rest },
    })
  }

  const getNodeIndexFromPath = path => {
    if (!nodesPathRegex.test(path)) return null
    const matches = path.match(/workflow.spec.nodes\[(?<nodeIdx>[0-9]*)/)
    return matches && matches.groups && matches.groups.nodeIdx
  }

  /*
   * HOC to wrap common actions, creating an <action>AndShare thunk.
   */
  const createActionAndShare =
    actionName => payload => (dispatch, getState) => {
      const action = actions[actionName](payload)
      const nodeIdx = getNodeIndexFromPath(action.payload.name)
      dispatch(action)

      if (nodeIdx) {
        const node = getNodeAtIndex(nodeIdx, getState())
        updateYDocNode(node, null, action)
      }
    }

  const setAndShare = createActionAndShare("set")
  const appendAndShare = createActionAndShare("append")
  const removeAndShare = createActionAndShare("remove")

  /*
   * The following thunks hold logic to perform local actions and conditionally
   * share state based via yDoc and ws.
   */
  const setNewCellType = payload => dispatch => {
    if (payload.value.type === "script" && !payload.value.cells.length) {
      const newCell = initNewScriptCell()
      insertYDocCell({ nodeId: payload.value.id, newCell })

      return dispatch(
        setAndShare({
          ...payload,
          value: {
            ...payload.value,
            cells: [newCell],
          },
        })
      )
    }
    if (payload.value.type === "component" && !payload.value.cells.length) {
      const newCell = initNewCell()
      insertYDocCell({ nodeId: payload.value.id, newCell })

      return dispatch(
        setAndShare({
          ...payload,
          value: {
            ...payload.value,
            cells: [newCell],
          },
        })
      )
    }

    if (payload.value.type === "script" || payload.value.type === "component") {
      yDoc &&
        yDoc.transact(() => {
          payload.value.cells.forEach((cell, cellIdx) => {
            insertYDocCell({ nodeId: payload.value.id, newCell: cell, cellIdx })
          })
          syncYNode(dispatch)(payload.value)
        })
    }

    dispatch(setAndShare(payload))
  }

  const insertCell = curry(
    ({ cellIdx, nodeIdx, code = "" }, dispatch, getState) => {
      const node = getNodeAtIndex(nodeIdx, getState())
      if (!node) return

      const path = getNodePath(nodeIdx)

      const newCell =
        node.type === "component" ? initNewCell() : initNewScriptCell()

      if (code) {
        newCell.source = [code]
      }

      const newCells = [
        ...node.cells.slice(0, cellIdx),
        newCell,
        ...node.cells.slice(cellIdx, node.cells.length),
      ]

      const action = actions.set({ name: `${path}.cells`, value: newCells })
      insertYDocCell({ nodeId: node.id, newCell, cellIdx })
      dispatch(action)
    }
  )

  const insertAfterCell = curry(({ cellIdx, nodeIdx }, dispatch, getState) => {
    insertCell({ cellIdx: cellIdx + 1, nodeIdx }, dispatch, getState)
  })

  const appendCell = curry(({ nodeIdx }, dispatch, getState) => {
    const path = getNodePath(nodeIdx)
    const node = getNodeAtIndex(nodeIdx, getState())
    const isAction = node.metadata && Boolean(node.metadata[ACTION_ID_LABEL])
    if (!node || isAction || node.type !== "script") return

    const newCell =
      node.type === "component" ? initNewCell() : initNewScriptCell()

    const newCells = [...node.cells, newCell]
    const action = actions.set({ name: `${path}.cells`, value: newCells })

    insertYDocCell({ nodeId: node.id, newCell })
    dispatch(action)
  })

  const deleteCell = curry(({ nodeIdx, cellIdx }, dispatch, getState) => {
    const node = getNodeAtIndex(nodeIdx, getState())
    if (!node) return

    const { id: nodeId } = node
    const { uuid } = node.cells[cellIdx] || []
    const name = `${getNodePath(nodeIdx)}.cells`

    if (node.cells.length === 1) return

    const cell = node.cells[cellIdx]
    const { source } = cell

    if (source.length <= 1 && !source[0]) {
      const action = actions.remove({ name, idx: cellIdx })
      dispatch(action)
      removeYDocCell({ nodeId, cellIdx, uuid })
    }
  })

  const withFocus = fn => () => (dispatch, getState) => {
    const state = getState()
    const { notebook = {}, workflow = {} } = state.notebook
    const focused = notebook.focused
    if (!focused.node) return
    const nodeIdx = getNodeIdxById(focused.node, workflow.spec.nodes)
    const cellIdx = focused.cellIdx
    fn({ cellIdx, nodeIdx }, dispatch, getState)
  }

  const insertCurrentCell = withFocus(insertCell)
  const insertAfterCurrentCell = withFocus(insertAfterCell)
  const appendCurrentCell = withFocus(appendCell)
  const deleteCurrentCell = withFocus(deleteCell)

  const addNode =
    ({ x = 100, y = 100, ...rest }) =>
    dispatch => {
      const value = {
        id: alphanumid(),
        type: "empty",
        position: roundCoords(x, y),
        ...rest,
        data: {
          color: "#0C2963",
          // collapsed: false,
          label: "New Node",
          // runningStatus: "ok",
        },
      }

      const action = actions.appendNode(value)

      addYDocNode(value, dispatch)
      dispatch(action)
      return value
    }

  const updateNodeField =
    ({ nodeIdx, field, value }) =>
    (dispatch, getState) => {
      const nodeName = `workflow.spec.nodes[${nodeIdx}]`
      const name = `${nodeName}.${field}`

      dispatch(actions.set({ name, value }))
      const newState = getState()
      const node = get(newState, `notebook.${nodeName}`)
      updateYDocNode(node, [field])
    }

  const addEdge =
    ({ source, target, type, id }) =>
    dispatch => {
      const value = { type, source, target, id, parameters: [] }
      const action = actions.append({ name: "workflow.spec.edges", value })

      dispatch(action)
      addYDocEdge(value)
    }

  const updateNode =
    ({ node }) =>
    (dispatch, getState) => {
      const {
        notebook: {
          workflow: {
            spec: { nodes },
          },
        },
      } = getState()
      const idx = nodes.findIndex(n => n.id === node.id)
      const prevNode = nodes[idx]
      const aligned = {
        ...prevNode,
        ...node,
        ...roundCoords(node.position.x, node.position.y),
      }

      if (!isEqual(nodes[idx], aligned)) {
        const payload = {
          name: `workflow.spec.nodes[${idx}]`,
          value: aligned,
        }

        dispatch(setAndShare(payload))
      }
    }

  const updateNodes =
    ({ nodes }) =>
    dispatch => {
      nodes.forEach(node => dispatch(updateNode({ node })))
    }

  const deleteNode =
    ({ node }) =>
    (dispatch, getState) => {
      const {
        notebook: {
          workflow: {
            spec: { nodes, edges },
          },
        },
      } = getState()
      const newNodes = nodes.filter(n => n.id !== node.id)

      // Delete all connected edges when we delete a node.
      const newEdges = edges.filter(
        e => ![e.source, e.target].includes(node.id)
      )

      dispatch(actions.set({ name: "workflow.spec.edges", value: newEdges }))
      dispatch(actions.set({ name: "workflow.spec.nodes", value: newNodes }))

      inYTransaction([
        () => removeYDocEdge({ source: node.id }),
        () => removeYDocEdge({ target: node.id }),
        () => removeYDocNode(node.id),
      ])
    }

  const deleteNodes =
    ({ nodes }) =>
    dispatch => {
      nodes.forEach(node => dispatch(deleteNode({ node })))
    }

  const deleteEdge =
    ({ edge }) =>
    (dispatch, getState) => {
      const {
        notebook: {
          workflow: {
            spec: { edges },
          },
        },
      } = getState()

      const newEdges = edges.filter(e => {
        return !(
          e.source === edge.source &&
          e.target === edge.target &&
          e.id === edge.id
        )
      })

      const payload = { name: "workflow.spec.edges", value: newEdges }
      dispatch(setAndShare(payload))
      removeYDocEdge({ target: edge.target, source: edge.source, id: edge.id })
    }

  const deleteEdgebyId =
    ({ id }) =>
    (dispatch, getState) => {
      const {
        notebook: {
          workflow: {
            spec: { edges },
          },
        },
      } = getState()

      const edge = edges.find(e => e.id === id)
      if (edge) {
        const { source, target } = edge
        const edgesToDelete = edges.filter(
          e => e.source === source && e.target === target
        )
        edgesToDelete.forEach(edgeToDelete => {
          dispatch(deleteEdge({ edge: edgeToDelete }))
        })
      }
    }
  const removeCell = uuid => (dispatch, getState) => {
    const {
      notebook: {
        workflow: {
          spec: { nodes },
        },
      },
    } = getState()

    const nodeIndex = nodes.findIndex(node => {
      const cellIds = node.cells && node.cells.map(cell => cell.uuid)
      const matches = cellIds.filter(id => id === uuid).map(Boolean)
      if (matches.length) return true
      return false
    })

    if (nodeIndex > -1) {
      dispatch(
        actions.remove({
          name: `workflow.spec.nodes[${nodeIndex}].cells`,
          idx: nodes[nodeIndex].cells.findIndex(cell => cell.uuid === uuid),
        })
      )
    }
  }

  const updateCellOutputs =
    ({ nodeIdx, cellIdx, content, outputType }) =>
    (dispatch, getState) => {
      const node = getNodeAtIndex(nodeIdx, getState())
      const value = content.map(c => ({ ...c, output_type: outputType }))
      const name = `workflow.spec.nodes[${nodeIdx}].cells[${cellIdx}].outputs`

      dispatch(
        actions.join({
          name,
          value,
        })
      )

      const newState = getState()
      const newOutputs = get(newState, `notebook.${name}`)
      updateYDocCellOutputs(node.id, cellIdx, newOutputs)
    }

  const clearCellOutputs =
    ({ nodeIdx, cellIdx }) =>
    (dispatch, getState) => {
      const node = getNodeAtIndex(nodeIdx, getState())
      const name = `workflow.spec.nodes[${nodeIdx}].cells[${cellIdx}].outputs`

      dispatch(
        actions.set({
          name,
          value: [],
        })
      )

      const newState = getState()
      const newOutputs = get(newState, `notebook.${name}`)
      updateYDocCellOutputs(node.id, cellIdx, newOutputs)
    }

  const updateCellExecutionCount =
    ({ nodeIdx, cellIdx, executionCount }) =>
    (dispatch, getState) => {
      const node = getNodeAtIndex(nodeIdx, getState())
      const name = `workflow.spec.nodes[${nodeIdx}].cells[${cellIdx}].executionCount`

      dispatch(
        actions.set({
          name,
          value: executionCount,
        })
      )

      updateYDocCellExecutionCount(node.id, cellIdx, executionCount)
    }

  const updateVisibility =
    ({ all, single }) =>
    ({ cellId = "all", value: visible }) =>
    (dispatch, getState) => {
      if (cellId === "all") {
        dispatch(all({ value: visible }))
        inYTransaction(
          getNodesFromSpecOrNodes(getState()).forEach(node => {
            if (!node.cells) return () => {}
            node.cells.forEach((cell, cellIdx) => {
              updateYDocCellMetadata(node.id, cellIdx, cell.metadata)
            })
          })
        )
        return
      }

      dispatch(single({ cellId, visible }))
      const { cell, nodeId, cellIdx } = getCellById(cellId, getState())

      if (!cell || !cell.metadata) return
      updateYDocCellMetadata(nodeId, cellIdx, cell.metadata)
    }

  const updateCellOutputVisibility = updateVisibility({
    all: actions.setAllCellOutputs,
    single: actions.setCellOutputVisibility,
  })

  const updateCellCodeVisibility = updateVisibility({
    all: actions.setAllCellCode,
    single: actions.setCellCodeVisibility,
  })

  const updateCellType =
    ({ cellId, value }) =>
    (dispatch, getState) => {
      const { nodeId, nodeIdx, cellIdx } = getCellById(cellId, getState())
      const cellPath = `workflow.spec.nodes[${nodeIdx}].cells[${cellIdx}].cellType`

      dispatch(actions.set({ name: cellPath, value }))
      updateYDocCellType(nodeId, cellIdx, value)
      dispatch(clearCellOutputs({ nodeIdx, cellIdx }))
    }

  const updateNodeOutputs = payload => (dispatch, getState) => {
    const { nodeId } = payload
    dispatch(actions.addNodeOutputs(payload))

    updateYNodeAttributes({ getState, nodeId, keys: ["artifacts"] })
  }

  const resetNodeOutputs = payload => (dispatch, getState) => {
    const { nodeId } = payload
    dispatch(actions.clearNodeOutputs(payload))

    updateYNodeAttributes({ getState, nodeId, keys: ["artifacts"] })
  }

  const addArgument = payload => (dispatch, getState) => {
    const { nodeId } = payload
    dispatch(actions.addScriptArgument(payload))

    updateYNodeAttributes({ getState, nodeId, keys: ["arguments"] })
  }

  const removeArgument = payload => (dispatch, getState) => {
    const { nodeId } = payload
    dispatch(actions.removeScriptArgument(payload))

    updateYNodeAttributes({ getState, nodeId, keys: ["arguments"] })
  }

  const assignEdgeParameters = payload => (dispatch, getState) => {
    dispatch(actions.assignParameter(payload))

    const { spec } = getState().notebook.workflow
    syncYEdges(spec)
  }

  const setName = payload => (dispatch, getState) => {
    dispatch(actions.set({ name: "workflow.name", value: payload.value }))

    ySetWorkflowName(payload.value)
  }

  const reloadWorkflow = payload => dispatch => {
    dispatch(actions.loadWorkflow(payload))
    syncSpec(payload.spec, dispatch)
  }

  const syncMetadata = (cellId, getState) => {
    const { cell, nodeId, cellIdx } = getCellById(cellId, getState())
    const yCell = getYCellByIdAndIndex(nodeId, cellIdx)
    syncYCellMetaData(cell, yCell)
  }

  const toggleCellMute = payload => (dispatch, getState) => {
    const { cellId } = payload
    dispatch(actions.toggleMuteForCell(payload))
    syncMetadata(cellId, getState)
  }

  const setCellRunTime = payload => (dispatch, getState) => {
    const { cellId } = payload
    dispatch(actions.setCellRunTime(payload))
    syncMetadata(cellId, getState)
  }

  const restoreWorkflow = payload => dispatch => {
    dispatch(actions.setIsLoading(true))
    client
      .mutate({
        variables: payload,
        mutation: RESTORE_WORKFLOW_FROM_SNAPSHOT,
      })
      .then(({ data }) => {
        dispatch(reloadWorkflow(data.restoreWorkflow))
        dispatch(actions.setHistoryPanelClosed())
      })
      .catch(err => console.error(err))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }

  const withID = attr => o => ({ ...o, [attr]: alphanumid() })
  const withCellID = withID("uuid")
  const withArtifactID = withID("id")

  const getLabelIdValues = (pickerType, cloneNode) => {
    switch (pickerType) {
      case "action":
        return {
          [ACTION_ID_LABEL]: cloneNode.actionId,
          [ACTION_REVISION_ID_LABEL]: cloneNode.id,
        }
      case "component":
        return {
          [COMPONENT_ID_LABEL]: cloneNode.componentId,
          [COMPONENT_REVISION_ID_LABEL]: cloneNode.id,
        }
      default:
        return {}
    }
  }

  const getComponentOutputName = (pickerType, cloneNode) => {
    if (pickerType === "component") {
      const outputName = snakeCase(cloneNode.name)
      return {
        [COMPONENT_OUTPUT_NAME]: outputName,
      }
    }
    return {}
  }

  const cleanScriptAndCells = cloneNode => {
    const cleaned = cleanScript(cloneNode.spec)
    const cleanedCells = cleaned.cells.map(withCellID)

    return {
      ...cleaned,
      cells: cleanedCells,
    }
  }

  const mergeStates = (initial, cloneNode, pickerType) => {
    const { type } = cloneNode.spec
    const cleaned =
      type === "script" ? cleanScriptAndCells(cloneNode) : cloneNode.spec
    const metadata = {
      ...(initial.metadata || {}),
      ...getLabelIdValues(pickerType, cloneNode),
      ...getComponentOutputName(pickerType, cloneNode),
    }
    return {
      ...initial,
      ...cleaned,
      type: type,
      metadata,
      data: {
        label: cloneNode.name || initial.data.label,
        color: cloneNode.spec.color || initial.data.color,
      },
      position: initial.position,
      artifacts: cleaned.artifacts.map(withArtifactID),
    }
  }

  const getComponentFunctionName = componentCode => {
    if (!componentCode) return
    const dbaiIndex = componentCode
      .split("\n")
      .findIndex(line => line.includes("@dbai_component"))
    let functionName
    if (dbaiIndex !== -1) {
      const defLine = componentCode.split("\n")[dbaiIndex + 1]
      const match = defLine.match(/def (\w+)\(/)
      if (match && match.length > 1) {
        functionName = match[1]
      }
    }
    return functionName
  }

  const cloneIntoNode =
    ({ targetId, cloneNode, pickerType }) =>
    (dispatch, getState) => {
      const state = getState()
      const nodes = selectNodes(state)
      const targetNode = getNodeById(targetId, nodes)

      const value = mergeStates(targetNode, cloneNode, pickerType)
      const targetIdx = getNodeIdxById(targetId, nodes)

      if (pickerType === "component" && value.cells && value.cells[0]) {
        value.cells = value.cells.map((cell, idx) => {
          if (idx === 0) {
            return {
              ...cell,
              metadata: {
                ...cell.metadata,
                showCode: true,
                muted: false,
                showOutput: true,
              },
            }
          }
          return cell
        })
      }

      dispatch(
        setNewCellType({
          name: `workflow.spec.nodes[${targetIdx}]`,
          value,
        })
      )

      if (pickerType === "component") {
        const componentCode = value.cells[0].source[0]
        const functionName = getComponentFunctionName(componentCode)
        dispatch(insertCell({ cellIdx: 1, nodeIdx: targetIdx }))

        const outputCode = componentCode.includes("class Output")
          ? `${value.metadata.componentOutputName} = ${functionName}(input)`
          : `${functionName}(input)`

        dispatch(
          insertCell({
            cellIdx: 2,
            nodeIdx: targetIdx,
            code: "",
          })
        )
        dispatch(
          updateNodeField({
            nodeIdx: targetIdx,
            field: "type",
            value: "component",
          })
        )
      }
    }

  const cloneIntoCurrentNode =
    (cloneNode, pickerType) => (dispatch, getState) => {
      dispatch(
        cloneIntoNode({
          targetId: selectFocused(getState()).node,
          cloneNode,
          pickerType,
        })
      )
    }

  const switchActionCodeInNode =
    (targetNode, actionRevision) => (dispatch, getState) => {
      dispatch(
        cloneIntoNode({
          targetId: targetNode.id,
          cloneNode: actionRevision,
          fromAction: true,
        })
      )
    }

  // If the kernel restarts unexpectedly while a cell is still awaiting
  // execution completion, this will mark the cell with an error and show that
  // it and any other cells are not executing/awaiting execution.
  const handleKernelRestart = () => (dispatch, getState) => {
    const state = getState()
    const cellStatuses = state.notebook.notebook.cellStatuses
    const [runningCellId] = Object.entries(cellStatuses).find(
      ([cellId, status]) => {
        if (status === "loading") return true
        return false
      }
    ) || [null]

    if (!runningCellId) return null

    const cell = getCellById(runningCellId, state)
    const { cellId, cellIdx, nodeIdx } = cell
    const content = [
      {
        output_type: "error",
        ename: "KernelRestart",
        traceback: ["Kernel terminated prior to execution completion"],
      },
    ]
    const outputType = "error"
    dispatch(updateCellOutputs({ nodeIdx, cellIdx, content, outputType }))
    dispatch(actions.setCellStatus({ cellId, status: "error" }))
    dispatch(actions.stopWaitingCellStatuses())
  }

  const setAndSyncCell =
    ({ node, nodeIdx, cellIdx, sourceValue }) =>
    (dispatch, getState) => {
      dispatch(
        actions.setCellSource({
          nodeIdx,
          cellIdx,
          sourceValue,
        })
      )
      const cell =
        getState().notebook.workflow.spec.nodes[nodeIdx].cells[cellIdx]
      const yCell = getYCellByIdAndIndex(node.id, cellIdx)
      if (yCell) {
        syncYCell(cell, yCell)
      }
    }

  return {
    ...actions,
    addNode,
    addEdge,
    setName,
    removeCell,
    updateNode,
    updateNodes,
    deleteNode,
    deleteNodes,
    deleteEdge,
    deleteEdgebyId,
    appendCell,
    deleteCell,
    insertCell,
    addArgument,
    setAndShare,
    saveWorkflow,
    cloneIntoNode,
    switchActionCodeInNode,
    setCellRunTime,
    setAndSyncCell,
    appendAndShare,
    toggleCellMute,
    removeAndShare,
    removeArgument,
    reloadWorkflow,
    setNewCellType,
    updateCellType,
    restoreWorkflow,
    updateNodeField,
    resetNodeOutputs,
    clearCellOutputs,
    insertCurrentCell,
    handleKernelRestart,
    insertAfterCurrentCell,
    appendCurrentCell,
    deleteCurrentCell,
    updateCellOutputs,
    updateNodeOutputs,
    cloneIntoCurrentNode,
    assignEdgeParameters,
    updateCellExecutionCount,
    updateCellCodeVisibility,
    updateCellOutputVisibility,
  }
}
