import React, { useEffect, useState } from "react"
import { TreeSelect } from "antd"

import { useCurrentCustomer } from "@dbai/ui-staples"

import { useWidgetContext } from "../../../hooks"
import FormFieldWrapper from "../../FormFieldWrapper"
import { fetchS3BucketContents } from "@dbai/ui-staples"
import { decryptDatalakeResources } from "@dbai/ui-staples"

const buildFileTree = (folders, files) => {
  const treeData = []
  folders.forEach(folder => {
    treeData.push({
      title: folder.Prefix.split("/").filter(Boolean).pop(),
      value: folder.Prefix,
      children: [],
      selectable: false,
    })
  })
  files.forEach(file => {
    if (file.Key.endsWith("/")) return
    treeData.push({
      title: file.Key.split("/").pop(),
      value: file.Key,
      isLeaf: true,
    })
  })
  return treeData
}

const crypto = window.crypto

const DatalakeSelectCore = props => {
  const { value, onChange } = props
  const { appConfig } = useWidgetContext()
  const [customer] = useCurrentCustomer()
  const [bucket, setBucket] = useState(null)
  const [s3Client, setS3Client] = useState(null)
  const [treeData, setTreeData] = useState([])

  const key = appConfig.datalakeEncryptionKey

  const onLoadData = async folder => {
    const s3Contents = await fetchS3BucketContents(
      s3Client,
      bucket,
      folder.value
    )
    const files = buildFileTree(s3Contents.directories, s3Contents.files)
    const updateTreeData = (treeData, folderPath, files) => {
      return treeData.map(node => {
        if (node.title === folderPath[0]) {
          if (folderPath.length === 1) {
            return { ...node, children: files }
          } else {
            const updatedChildren = updateTreeData(
              node.children,
              folderPath.slice(1),
              files
            )
            return { ...node, children: updatedChildren }
          }
        }
        return node
      })
    }

    setTreeData(treeData => {
      const folderPath = folder.value.split("/").filter(Boolean)
      const newTreeData = updateTreeData(treeData, folderPath, files)
      return newTreeData
    })
  }

  useEffect(() => {
    async function getResources() {
      const decrypted = await decryptDatalakeResources(
        customer.datalakeResources,
        key,
        crypto
      )
      if (decrypted.Bucket) {
        setBucket(decrypted.Bucket)
      }
      if (decrypted.Credentials) {
        AWS.config.update({
          region: "us-east-1",
          accessKeyId: decrypted.Credentials.AccessKeyID,
          secretAccessKey: decrypted.Credentials.SecretAccessKey,
        })
        const s3 = new AWS.S3()
        setS3Client(s3)
      }
    }
    if (customer.datalakeResources) {
      getResources()
    }
  }, [customer.datalakeResources])

  useEffect(() => {
    if (!s3Client) return
    const getDataTree = async () => {
      const s3Content = await fetchS3BucketContents(s3Client, bucket, "/")
      const files = buildFileTree(s3Content.directories, s3Content.files)
      setTreeData(files)
    }
    getDataTree()
  }, [s3Client, bucket])

  //if (error) return <ErrorMessage error={error} />
  if (!treeData) return

  return (
    <TreeSelect
      {...props}
      showSearch
      treeExpandAction="click"
      style={{
        width: "100%",
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: "auto",
        minWidth: 500,
      }}
      placeholder="Please select"
      allowClear
      onChange={onChange}
      treeData={treeData}
      loadData={onLoadData}
    />
  )
}

const DatalakeSelect = props => {
  return (
    <FormFieldWrapper {...props}>
      <DatalakeSelectCore />
    </FormFieldWrapper>
  )
}

DatalakeSelect.Core = DatalakeSelectCore
export default DatalakeSelect
