import React from "react"
import { Tag, Tooltip } from "antd"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { useCurrentCustomer } from "@dbai/ui-staples"

const StyledLink = styled(Link)`
  display: block;
`

const FormatWorkflow = ({ value }) => {
  const [customer] = useCurrentCustomer()
  const { workflow, ...pkg } = value
  const workflowPath = `/${customer.normalizedName}/workflows/${workflow.id}`

  const renderImage = image => {
    if (!image) return "No Image"
    const imageName = image.split(":")[1] || image
    return imageName
  }

  return (
    <>
      <StyledLink to={workflowPath}>{workflow.name}</StyledLink>
      <Tooltip title={pkg.image}>
        <Tag>{renderImage(pkg.image)}</Tag>
      </Tooltip>
    </>
  )
}

export default FormatWorkflow
