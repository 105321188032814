import { isArray } from "lodash"

import { isSchemaHidden } from "./utils"
import BaseInput from "./fields/BaseInput"
import AnyOfField from "./fields/AnyOfField"
import CodeEditor from "./fields/CodeEditor"
import MixedInput from "./fields/MixedInput"
import ColorScale from "./custom/ColorScale"
import FontPicker from "./fields/FontPicker"
import DatePicker from "./fields/DatePicker"
import RichText from "./fields/RichTextInput"
import SectionList from "./fields/SectionList"
import NumberInput from "./fields/NumberInput"
import LocalSelect from "./fields/LocalSelect"
import SwitchInput from "./fields/SwitchInput"
import ColorPicker from "./fields/ColorPicker"
import LayoutInput from "./custom/LayoutInput"
import ColumnLabels from "./fields/ColumnLabels"
import ColumnSelect from "./fields/ColumnSelect"
import ThemeSelector from "./custom/ThemeSelector"
import DnDDropzone from "./containers/DndDropzone"
import SchemaArray from "./containers/SchemaArray"
import DatasetSelect from "./fields/DatasetSelect"
import NullableField from "./fields/NullableField"
import SchemaObject from "./containers/SchemaObject"
import SegmentedInput from "./fields/SegmentedInput"
import FormProperties from "./custom/FormProperties"
import EndpointSelect from "./fields/EndpointSelect"
import EditImageButton from "./custom/EditImageButton"
import ColumnListInput from "./custom/ColumnListInput"
import ColumnInputZones from "./custom/ColumnInputZones"
import UploadImageInput from "./fields/UploadImageInput"
import SliderInput from "./fields/NumberInput/SliderInput"
import Base64ImageUpload from "./fields/Base64ImageUpload"
import ColumnValueInput from "./fields/ColumnValueInput"
import FilterWidgetInput from "./custom/FilterWidgetInput"
import AppVariableSelect from "./fields/AppVariableSelect"
import DatasetAndColumnSelect from "./fields/DatasetAndColumnSelect"
import DatetimeFormatOptionsInput from "./custom/DatetimeFormatOptionsInput"
import GenericSchemaRender from "./fields/GenericSchemaRender"
import TextArea from "./fields/TextArea"
import DatalakeSelect from "./fields/DatalakeSelect"

const customFields = {
  LayoutInput,
  FormProperties,
  ColumnInputZones,
  DatetimeFormatOptionsInput,
  EditImageButton,
  ThemeSelector,
  ColorScale,
  ColumnListInput,
  FilterWidgetInput,
}

const fields = {
  RichText,
  FontPicker,
  AnyOfField,
  CodeEditor,
  none: null,
  MixedInput,
  DatePicker,
  SectionList,
  LocalSelect,
  ColorPicker,
  SliderInput,
  ColumnSelect,
  ColumnLabels,
  EndpointSelect,
  Base64ImageUpload,
  string: BaseInput,
  ColumnValueInput,
  number: NumberInput,
  AppVariableSelect,
  integer: NumberInput,
  boolean: SwitchInput,
  DatasetAndColumnSelect,
  Dataset: DatasetSelect,
  Upload: UploadImageInput,
  Segmented: SegmentedInput,
  TextArea,
  DatalakeSelect,
}

const containers = {
  array: SchemaArray,
  object: SchemaObject,
  DnDDropzone: DnDDropzone,
}

const formFieldRegistry = {
  ...fields,
  ...containers,
  ...customFields,
}

const getFormField = (schema, path) => {
  if (!schema) return null
  const { type, anyOf, nullable, metadata, items = {}, enum: enums } = schema
  switch (true) {
    case nullable && isArray(type):
      if (type.includes("null") && type.length === 2) {
        return NullableField
      }
      const inputType = type.find(t => t !== "null")
      return formFieldRegistry[inputType] || null
    case Boolean(metadata?.hidden):
      const hidden = isSchemaHidden(schema, {}, path)
      if (hidden) return null
      const { hidden: hiddenProp, ...restMetadata } = metadata
      return getFormField({
        ...schema,
        metadata: restMetadata,
      })

    case Boolean(metadata?.render):
      return GenericSchemaRender
    case Boolean(metadata?.component):
      return formFieldRegistry[metadata.component]
    case isArray(type):
      return formFieldRegistry.MixedInput
    case type === "array" && Boolean(items.enum):
    case Boolean(enums):
      return formFieldRegistry.LocalSelect
    case Boolean(anyOf):
      return formFieldRegistry.AnyOfField
    default:
      return formFieldRegistry[type] || null
  }
}

export default getFormField
