import React, { useCallback } from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { Card, Input, Row, Col } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { useAction } from "hooks"
import { actions } from "reducers/componentReducer"
import withCustomer from "components/shared/withCustomer"
import {
  selectComponent,
} from "selectors"
import ColorPicker from "../ColorPicker"
import RevisionsTable from "./RevisionsTable"

const { TextArea } = Input

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`

const NameEditor = props => {
  const { value, onChange } = props
  const handleChange = useCallback(e => onChange(e.target.value, e), [onChange])

  return (
    <div className="form-group">
      <label className="form-label">Name</label>
      <Input id="name" value={value} onChange={handleChange} name="name" />
    </div>
  )
}

const DescriptionEditor = props => {
  const { value, onChange } = props
  const handleChange = useCallback(e => onChange(e.target.value, e), [onChange])

  return (
    <div className="form-group">
      <label className="form-label">Description</label>
      <TextArea
        id="description"
        value={value}
        onChange={handleChange}
        name="description"
      />
    </div>
  )
}

const ComponentSettings = props => {
  const dispatch = useDispatch()
  const { customer } = props
  const { id: componentId } = useParams()
  const { id, name, description } = useSelector(selectComponent)
  const closePanel = useCallback(() => dispatch(actions.reset()), [dispatch])

  const handleNameChange = useAction(actions.setName)
  const handleDescriptionChange = useAction(actions.setDescription)

  return (
    <Row gutter={[16, 16]}>
      <Col span={16}>
        <Card>
          <ColorPicker />
          <NameEditor value={name} onChange={handleNameChange} />
          <DescriptionEditor
            value={description}
            onChange={handleDescriptionChange}
          />
        </Card>
      </Col>
      <Col span={8}>
        <StyledCard title="Revision History">
          <RevisionsTable
            showNew={id}
            onNewClose={closePanel}
            customerId={customer.id}
            componentId={componentId}
            cname={customer.normalizedName}
          />
        </StyledCard>
      </Col>
    </Row>
  )
}

export default withCustomer(ComponentSettings)
