import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { createSlice } from "@reduxjs/toolkit"

import {
  capitalize,
  toastAndRethrow,
  createCommonActions,
} from "@dbai/tool-box"

import client from "apolloClient"
import {
  GET_SCHEDULE,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_EMAIL_NOTIFICATION,
  DELETE_SCHEDULE_EMAIL_NOTIFICATION,
} from "queries"

dayjs.extend(utc)
dayjs.extend(timezone)

const initialState = {
  id: null,
  packageId: null,
  createdAt: "",
  updatedAt: "",
  notifications: [],
  spec: {
    timezone: dayjs.tz.guess(),
    // NOTE: For convenience, these fields are ordered from top to bottom in
    // the same manner as Cron syntax, specifically:
    //   minute - hour - Day of Month - Month - Day of Week
    //
    // The `step` indicates at what interval (if any) the task should be
    // repeated after the first occurence.
    //
    // Empty arrays are treated as `*` in Cron.
    //
    // All values are strings, and acceptable formats are:
    //   1. "1"
    //   2. "1-4"
    //   3. "*"
    minute: [
      // 0 = The task should be performed on the hour.
      { value: "0", step: null },
    ],
    hour: [{ value: "12", step: null }],
    // The days of the month.
    dom: [
      // * = the task should be performed Every Hour of the day (24hr format).
      { value: "*", step: null },
    ],
    // The months of the year.
    month: [{ value: "*", step: null }],
    // The day of the week.
    dow: [],
  },
}

const createCronActions = (field, options = {}) => {
  const { defaultValue = "0", allowZeroStep = false } = options
  const capped = capitalize(field)

  return {
    [`add${capped}`]: (draft, { payload }) => {
      draft.spec[field] = [
        ...draft.spec[field],
        { value: defaultValue, step: null },
      ]
    },
    [`remove${capped}`]: (draft, { payload }) => {
      const { idx } = payload
      draft.spec[field] = draft.spec[field].filter((_, i) => i !== idx)
    },
    [`set${capped}Start`]: (draft, { payload }) => {
      const { idx, start } = payload

      if (start === "*") {
        draft.spec[field][idx].value = start
        return
      }

      const parts = draft.spec[field][idx]?.value?.split("-") || ""
      const newVal = parts.length > 1 ? `${start}-${parts[1]}` : start
      draft.spec[field][idx].value = newVal
    },
    [`set${capped}End`]: (draft, { payload }) => {
      const { idx, end } = payload
      const parts = (draft.spec[field][idx]?.value || "").split("-")
      const newVal = !end ? parts[0] : `${parts[0]}-${end}`

      draft.spec[field][idx].value = newVal
    },
    [`set${capped}Step`]: (draft, { payload }) => {
      const { idx, step } = payload
      const { value, ...rest } = draft.spec[field][idx]

      // Some fields should not allow things like "0/2" in the cron, but
      // should instead use "*/2". If this is one of those fields, change 0->*
      // when a step is set.
      const shouldResetValue = !allowZeroStep && !!step && value === "0"

      draft.spec[field][idx] = {
        ...rest,
        value: shouldResetValue ? "*" : value,
        step: step,
      }
    },
  }
}

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    resetSchedule: state => {
      state.spec = initialState.spec
    },
    load: (draft, { payload }) => {
      const { __typename, ...rest } = payload
      return rest
    },
    presetHourly: draft => ({
      ...draft,
      spec: {
        ...draft.spec,
        hour: [{ value: "*", step: null }],
        minute: [{ value: "0", step: null }],
      },
    }),
    presetWeekdays: draft => ({
      ...draft,
      spec: {
        ...draft.spec,
        dow: [
          { value: "1", step: null },
          { value: "2", step: null },
          { value: "3", step: null },
          { value: "4", step: null },
          { value: "5", step: null },
        ],
      },
    }),
    presetEveryOtherDay: draft => ({
      ...draft,
      spec: {
        ...draft.spec,
        dom: [{ value: "1-31", step: 2 }],
      },
    }),
    presetMonthly: draft => ({
      ...draft,
      spec: {
        ...draft.spec,
        dom: [{ value: "1", step: null }],
        month: [{ value: "*", step: null }],
      },
    }),
    presetQuarterly: draft => ({
      ...draft,
      spec: {
        ...draft.spec,
        dom: [{ value: "1", step: null }],
        month: [
          { value: "1", step: null },
          { value: "4", step: null },
          { value: "7", step: null },
          { value: "10", step: null },
        ],
      },
    }),
    ...createCronActions("hour"),
    ...createCronActions("minute"),
    ...createCronActions("dom", { defaultValue: "1", allowZeroStep: true }),
    ...createCronActions("month"),
    ...createCommonActions(initialState),
  },
})

const createSchedule = opts => (dispatch, getState) => {
  const mutation = CREATE_SCHEDULE
  const { id, createdAt, updatedAt, notifications, ...input } =
    getState().schedule
  const variables = { input, ...opts.variables }

  return client.mutate({ variables, mutation })
}

const loadSchedule = payload => dispatch => {
  const query = GET_SCHEDULE

  return client
    .query({ query, variables: payload, fetchPolicy: "network-only" })
    .then(result => {
      dispatch(scheduleSlice.actions.load(result.data?.customer?.schedule))
      return result
    })
}

const reloadSchedule = () => (dispatch, getState) => {
  const {
    schedule: { id },
    currentCustomer: {
      customer: { normalizedName: cname },
    },
  } = getState()

  return dispatch(loadSchedule({ cname, id }))
}

const createNotification = payload => (dispatch, getState) => {
  const { eventType, addresses, content } = payload
  const {
    schedule: { id: scheduleId },
    currentCustomer: {
      customer: { normalizedName: cname },
    },
  } = getState()

  const mutation = CREATE_SCHEDULE_EMAIL_NOTIFICATION
  const variables = {
    cname,
    scheduleId,
    eventType,
    addresses,
    content,
  }

  return client
    .mutate({ variables, mutation })
    .then(() => {
      dispatch(reloadSchedule())
    })
    .catch(toastAndRethrow("Error Creating Notification"))
}

const deleteNotification = payload => (dispatch, getState) => {
  const {
    currentCustomer: {
      customer: { normalizedName: cname },
    },
  } = getState()

  const mutation = DELETE_SCHEDULE_EMAIL_NOTIFICATION
  const variables = { id: payload, cname }

  return client
    .mutate({ variables, mutation })
    .then(() => {
      dispatch(reloadSchedule())
    })
    .catch(toastAndRethrow("Error Deleting Notification"))
}

const allActions = {
  loadSchedule,
  createSchedule,
  createNotification,
  deleteNotification,
  ...scheduleSlice.actions,
}

export { allActions as actions }
export default scheduleSlice
